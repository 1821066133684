<template>
    <v-footer class="text-center d-flex flex-column footer">
        <h1> IMPRESSUM </h1>
        <div class="pt-3 impressum">
            <div>
                <v-btn v-for="icon in icons" :key="icon" class="mx-4" :icon="icon" variant="text" :href="socialLink(icon)">
                </v-btn>
            </div>
            <div class="pr-5">
                <p>Hangover Bar</p>
                <p>Karlsplatz 3</p>
                <p>71638 Ludwigsburg</p>
                <p>Tel. 07141 / 4873271</p>
                <p>E-Mail: info@hangover-lb.de</p>
            </div>
            <div>
                <p>Inhaber: Ramez Salim</p>
            </div>
        </div>
    </v-footer>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    data: () => {
        return {
            icons: [
                'mdi-facebook',
                'mdi-instagram',
            ],
        }
    },
    methods: {
        socialLink(icon: String): string {
            if (icon == 'mdi-facebook') {
                return "https://www.facebook.com/hangoverlb/"
            }
            return "https://www.instagram.com/hangover_shisha_bar_lounge/"
        }
    }
})

</script>

<style>
.footer {
    background-color: black;
    color: white;
}

h1 {
    text-align: center;
    font-family: pixie;
}

.impressum {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    font-size: smaller;
}
</style>