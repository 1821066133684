<template>
    <v-expansion-panel>
        <template #title>
            <div class="title">
                <span>
                    {{ title }}
                </span>
                <p v-if="price" class="price"> {{ price }} €</p>
            </div>
        </template>
        <template #text>
            <v-list lines="one" density="compact">
                <v-list-item v-for="shisha in products" :key="shisha.name" :subtitle="shisha.description">
                    <template #title>
                        <div class="icon-text">
                            <p>{{ shisha.name }}</p>
                        </div>
                    </template>
                </v-list-item>
            </v-list>
        </template>
    </v-expansion-panel>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { Shisha } from '../types/Product';


export default defineComponent({

    props: {
        title: {
            type: String,
            required: true,
        },
        price: {
            type: Number,
            required: false
        },
        products: {
            type: Array as PropType<Shisha[]>,
            required: false,
            default: () => []
        }
    }
})

</script>

<style>
.icon-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.icon-text>p {
    padding-right: 10px;
}

.price {
    color: orange;
    margin-left: 10px;
    font-style: italic;
}

.title {
    display: inline-flex;
}
</style>
