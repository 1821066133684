import { Icon } from "./Icon";

export enum ShishaCategory {
    ORIENTAL = 'oriental',
    MODERN = 'modern'
}

export interface Shisha {
    name: string;
    description: string;
    category: ShishaCategory;
    icons: Icon[];
}
