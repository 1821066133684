<template>
    <div class="ml-5 mr-5">
        <h1> SHISHAS </h1>
        <v-expansion-panels>
            <ExpansionPanel title="Orientalisch" :price=16 :products="oriental"></ExpansionPanel>
            <ExpansionPanel title="Modern" :price=16 :products="modern"></ExpansionPanel>
        </v-expansion-panels>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { shishas } from '@/collections/shishas';
import ExpansionPanel from './common/ExpansionPanel.vue'
import { ShishaCategory } from './types/Product';

export default defineComponent({
    components: { ExpansionPanel },
    data: () => {
        return {
            oriental: shishas.filter((shisha) => shisha.category === ShishaCategory.ORIENTAL),
            modern: shishas.filter((shisha) => shisha.category === ShishaCategory.MODERN)
        }
    }
})
</script>

<style>
h1 {
    text-align: center;
    font-family: pixie;
}
</style>