<template>
    <div class="openingHours">
        <h1 id="title">oFFNUNGSZEITEN</h1>
        <p>Montag - Donnerstag</p>
        <p>12:00 Uhr - 01:00 Uhr</p>
        <p>-------</p>
        <p>Freitag</p>
        <p>12:00 Uhr - 03:00 Uhr</p>
        <p>-------</p>
        <p>Samstag</p>
        <p>14:00 Uhr - 03:00 Uhr</p>
        <p>-------</p>
        <p>Sonntag</p>
        <p>14:00 Uhr - 01:00 Uhr</p>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
}) 
</script>

<style>
.openingHours {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'Roboto';
    padding: 30px 8px 30px 8px;
    background-color: #3c3c3c;
}

#title {
    font-family: pixie;
    color: white;
    text-align: center;
    margin-bottom: 10px;
}
</style>