export enum DrinksCategory {
    HOTDRINKS = 'hotDrinks',
    NOALCDRINKS = 'noAlcDrinks',
    BEER = 'beer',
    LONGDRINKS = 'longDrinks',
    COCKTAILS = 'cocktails',
    NOALCCOCKTAILS = 'noAlcCocktails',
    APERITIFS = 'aperitifs',
    VINE = 'vine',
    SHOTS = 'shots',
    BOTTLES = 'bottles'
}

export interface Drink {
    name: string;
    description?: string;
    category: DrinksCategory;
    price: number
}

export const drinks: Drink[] = [
    {
        name: "Espresso",
        category: DrinksCategory.HOTDRINKS,
        price: 2.80
    },
    {
        name: "Kaffee",
        category: DrinksCategory.HOTDRINKS,
        price: 3.20
    },
    {
        name: "Cappuccino",
        category: DrinksCategory.HOTDRINKS,
        price: 3.80
    },
    {
        name: "Latte Macchiato",
        category: DrinksCategory.HOTDRINKS,
        price: 3.80
    },
    {
        name: "Heiße Schokolade",
        category: DrinksCategory.HOTDRINKS,
        price: 3.80
    },
    {
        name: "Tee",
        category: DrinksCategory.HOTDRINKS,
        price: 3.40
    },
    {
        name: "Wasser ",
        description:"(Still, Sprudel)",
        category: DrinksCategory.NOALCDRINKS,
        price: 2.90
    },
    {
        name: "Apfelschorle",
        category: DrinksCategory.NOALCDRINKS,
        price: 3.80
    },
    {
        name: "Cola, Fanta, Sprite, Mezzo Mix",
        category: DrinksCategory.NOALCDRINKS,
        price: 3.20
    },
    {
        name: "Schweppes",
        description: "(Wildberry, Tonic, Ginger Ale)",
        category: DrinksCategory.NOALCDRINKS,
        price: 3.60
    },
    {
        name: "Red Bull",
        description: "(Alle Sorten)",
        category: DrinksCategory.NOALCDRINKS,
        price: 4.20
    },
    {
        name: "Schwarze Dose",
        category: DrinksCategory.NOALCDRINKS,
        price: 4.20
    },
    {
        name: "Moloko",
        category: DrinksCategory.NOALCDRINKS,
        price: 4.20
    },
    {
        name: "Orangina",
        category: DrinksCategory.NOALCDRINKS,
        price: 3.90
    },
    {
        name: "ElephantBay Ice-Tea",
        category: DrinksCategory.NOALCDRINKS,
        price: 4.20
    },
    {
        name: "Säfte",
        description: "(KiBa, O-Saft)",
        category: DrinksCategory.NOALCDRINKS,
        price: 4.50
    },
    {
        name: "Beck`s",
        description: "(Lemon, Green)",
        category: DrinksCategory.BEER,
        price: 4.20
    },
    {
        name: "Wulle",
        category: DrinksCategory.BEER,
        price: 4.50
    },
    {
        name: "Paulaner",
        description: "(Kristal, Hefe)",
        category: DrinksCategory.BEER,
        price: 4.20
    },
    {
        name: "Heineken",
        category: DrinksCategory.BEER,
        price: 4.20
    },
    {
        name: "Colaweizen",
        category: DrinksCategory.BEER,
        price: 3.80
    },
    {
        name: "Radler",
        category: DrinksCategory.BEER,
        price: 4.60
    },
    {
        name: "Bananenweizen",
        category: DrinksCategory.BEER,
        price: 4.60
    },
    {
        name: "Desperados",
        category: DrinksCategory.BEER,
        price: 4.50
    },
    {
        name: "Corona",
        category: DrinksCategory.BEER,
        price: 4.50
    },
    {
        name: "Larios & Tonic Water",
        description: "Gin",
        category: DrinksCategory.LONGDRINKS,
        price: 6.50
    },
    {
        name: "The Duke & Tonic Water",
        description: "Gin",
        category: DrinksCategory.LONGDRINKS,
        price: 7.50
    },
    {
        name: "Vodka & Redbull",
        description: "Vodka",
        category: DrinksCategory.LONGDRINKS,
        price: 6.50
    },
    {
        name: "Vodka & Saft nach Wahl",
        description: "Vodka",
        category: DrinksCategory.LONGDRINKS,
        price: 7.00
    },
    {
        name: "Jim Beam & Coca Cola",
        description: "Whiskey",
        category: DrinksCategory.LONGDRINKS,
        price: 6.50
    },
    {
        name: "Jack Daniel`s & Coca Cola",
        description: "Whiskey",
        category: DrinksCategory.LONGDRINKS,
        price: 6.50
    },
    {
        name: "Chivas Regal 'On the Rocks'",
        description: "Whiskey",
        category: DrinksCategory.LONGDRINKS,
        price: 7.50
    },
    {
        name: "Chivas Regal & Coca Cola",
        description: "Whiskey",
        category: DrinksCategory.LONGDRINKS,
        price: 7.50
    },
    {
        name: "Swimming Pool",
        category: DrinksCategory.NOALCCOCKTAILS,
        price: 6.50
    },
    {
        name: "Coconut Kiss",
        category: DrinksCategory.NOALCCOCKTAILS,
        price: 6.50
    },
    {
        name: "Ipanema",
        category: DrinksCategory.NOALCCOCKTAILS,
        price: 7.50
    },
    {
        name: "Sex on the Beach",
        category: DrinksCategory.NOALCCOCKTAILS,
        price: 7.50
    },
    {
        name: "Surfer`s Trip",
        category: DrinksCategory.NOALCCOCKTAILS,
        price: 7.50
    },
    {
        name: "Pina Colada",
        category: DrinksCategory.COCKTAILS,
        price: 7.50
    },
    {
        name: "Swimming Pool",
        category: DrinksCategory.COCKTAILS,
        price: 7.50
    },
    {
        name: "Tequila Sunrise",
        category: DrinksCategory.COCKTAILS,
        price: 7.80
    },
    {
        name: "Sex on the Beach",
        category: DrinksCategory.COCKTAILS,
        price: 7.80
    },
    {
        name: "Dangerous",
        category: DrinksCategory.COCKTAILS,
        price: 7.80
    },
    {
        name: "Zombie",
        category: DrinksCategory.COCKTAILS,
        price: 8.80
    },
    {
        name: "Caipirinha",
        category: DrinksCategory.COCKTAILS,
        price: 8.80
    },
    {
        name: "Mojito",
        category: DrinksCategory.COCKTAILS,
        price: 8.80
    },
    {
        name: "Cuba Libre",
        category: DrinksCategory.COCKTAILS,
        price: 8.80
    },
    {
        name: "Long Island Ice Tea",
        category: DrinksCategory.COCKTAILS,
        price: 8.80
    },
    {
        name: "Minze",
        description: "Hugo",
        category: DrinksCategory.APERITIFS,
        price: 6.50
    },
    {
        name: "Soda",
        description: "Hugo",
        category: DrinksCategory.APERITIFS,
        price: 6.50
    },
    {
        name: "Holundersirup",
        description: "Hugo",
        category: DrinksCategory.APERITIFS,
        price: 6.50
    },
    {
        name: "Prosecco",
        description: "Hugo",
        category: DrinksCategory.APERITIFS,
        price: 6.50
    },
    {
        name: "Prosecco",
        description: "Aperol Spritz",
        category: DrinksCategory.APERITIFS,
        price: 6.50
    },
    {
        name: "Orange",
        description: "Aperol Spritz",
        category: DrinksCategory.APERITIFS,
        price: 6.50
    },
    {
        name: "Standard",
        description: "Aperol Spritz",
        category: DrinksCategory.APERITIFS,
        price: 6.50
    },
    {
        name: "Soda",
        description: "Aperol Spritz",
        category: DrinksCategory.APERITIFS,
        price: 6.50
    },
    {
        name: "Prosecco",
        description: "Lillet",
        category: DrinksCategory.APERITIFS,
        price: 6.50
    },
    {
        name: "Wild Berry",
        description: "Lillet",
        category: DrinksCategory.APERITIFS,
        price: 6.50
    },
    {
        name: "Standard",
        description: "Lillet",
        category: DrinksCategory.APERITIFS,
        price: 6.50
    },
    {
        name: "Rotwein",
        category: DrinksCategory.VINE,
        price: 4.80
    },
    {
        name: "Weißwein",
        category: DrinksCategory.VINE,
        price: 4.80
    },
    {
        name: "Weinschorle",
        category: DrinksCategory.VINE,
        price: 5.50
    },
    {
        name: "Jägermeister",
        category: DrinksCategory.SHOTS,
        price: 2.50
    },
    {
        name: "Vodka",
        category: DrinksCategory.SHOTS,
        price: 2.50
    },
    {
        name: "Jack Daniel`s",
        category: DrinksCategory.SHOTS,
        price: 2.50
    },
    {
        name: "Raki",
        category: DrinksCategory.SHOTS,
        price: 2.50
    },
    {
        name: "Ramazotti",
        category: DrinksCategory.SHOTS,
        price: 2.50
    },
    {
        name: "Champagner Moët",
        category: DrinksCategory.BOTTLES,
        price: 90.00
    },
    {
        name: "Champagner Veuve",
        category: DrinksCategory.BOTTLES,
        price: 90.00
    },
    {
        name: "Belvedere / Grey Goose",
        category: DrinksCategory.BOTTLES,
        price: 90.00
    },
    {
        name: "Jack Daniel`s",
        category: DrinksCategory.BOTTLES,
        price: 75.00
    },
    {
        name: "Absolut Vodka",
        category: DrinksCategory.BOTTLES,
        price: 65.00
    },










]
