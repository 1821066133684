import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "title" }
const _hoisted_2 = {
  key: 0,
  class: "price"
}
const _hoisted_3 = { class: "icon-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!

  return (_openBlock(), _createBlock(_component_v_expansion_panel, null, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(_ctx.title), 1),
        (_ctx.price)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.price) + " €", 1))
          : _createCommentVNode("", true)
      ])
    ]),
    text: _withCtx(() => [
      _createVNode(_component_v_list, {
        lines: "one",
        density: "compact"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (shisha) => {
            return (_openBlock(), _createBlock(_component_v_list_item, {
              key: shisha.name,
              subtitle: shisha.description
            }, {
              title: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("p", null, _toDisplayString(shisha.name), 1)
                ])
              ]),
              _: 2
            }, 1032, ["subtitle"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}