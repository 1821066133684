<template>
  <v-app>
    <HeaderComponent @item:clicked="scroll" />
    <BodyComponent :scroll-element="scrollElement" />
    <FooterComponent/>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HeaderComponent from './components/HeaderComponent.vue';
import BodyComponent from './components/BodyComponent.vue';
import FooterComponent from './components/FooterComponent.vue';

export default defineComponent({
  name: "App",
  components: { HeaderComponent, BodyComponent, FooterComponent },
  data() {
    return {
      scrollElement: ''
    }
  },
  methods: {
    scroll(event: any): void {
      this.scrollElement = event.id;
    }
  }
})
</script>

<style></style>
