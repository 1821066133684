import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22ea398a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icon-text" }
const _hoisted_2 = { id: "price" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!

  return (_openBlock(), _createBlock(_component_v_expansion_panel, { title: _ctx.title }, {
    text: _withCtx(() => [
      _createVNode(_component_v_list, {
        lines: "two",
        density: "compact"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (drink) => {
            return (_openBlock(), _createBlock(_component_v_list_item, {
              key: drink.name
            }, {
              title: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("p", null, _toDisplayString(drink.name), 1),
                  _createElementVNode("p", _hoisted_2, _toDisplayString(drink.price.toFixed(2)), 1)
                ])
              ]),
              subtitle: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(drink.description), 1)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_divider, {
                  thickness: "2",
                  inset: ""
                })
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title"]))
}