import { Icon } from "@/components/types/Icon";
import { Shisha, ShishaCategory } from "@/components/types/Product";

export const shishas: Shisha[] = [
    {
        name: "Traube Minze",
        description: "(Traube, Minze)",
        category: ShishaCategory.ORIENTAL,
        icons: [{
            name: "mdi-fruit-grapes",
            color: "purple"
        } as Icon,
        {
            name: "mdi-fruit-cherries",
            color: "red"
        } as Icon
        ]
    },
    {
        name: "Hamburg",
        description: "(Beerenmix)",
        category: ShishaCategory.MODERN,
        icons: [
            {
                name: "mdi-fruit-grapes",
                color: "purple"
            } as Icon
        ]
    },
    {
        name: "Kaugummi/Falim",
        description: "(Minzkaugummi, Erdbeere)",
        category: ShishaCategory.ORIENTAL,
        icons: [
            {
                name: "mdi-fruit-pineapple",
                color: "#563c0d"
            } as Icon
        ],
    },
    {
        name: "Doppel-Apfel",
        description: "(Anis)",
        category: ShishaCategory.ORIENTAL,
        icons: [
            {
                name: "mdi-fruit-pineapple",
                color: "#563c0d"
            } as Icon
        ],
    },
    {
        name: "Persischer Apfel",
        description: "(Apfel)",
        category: ShishaCategory.ORIENTAL,
        icons: [
            {
                name: "mdi-fruit-pineapple",
                color: "#563c0d"
            } as Icon
        ],
    },
    {
        name: "Black Nana",
        description: "(Schwarze Traube, Minze)",
        category: ShishaCategory.ORIENTAL,
        icons: [
            {
                name: "mdi-fruit-pineapple",
                color: "#563c0d"
            } as Icon
        ],
    },
    {
        name: "Babos FFM 069",
        description: "(Waldbeeren, Erdbeere)",
        category: ShishaCategory.MODERN,
        icons: [
            {
                name: "mdi-fruit-pineapple",
                color: "#563c0d"
            } as Icon
        ],
    },
    {
        name: "African Queen",
        description: "(Mix aus 15 Früchten)",
        category: ShishaCategory.MODERN,
        icons: [
            {
                name: "mdi-fruit-pineapple",
                color: "#563c0d"
            } as Icon
        ],
    },
    {
        name: "Ice Kaktuz",
        description: "(Kaktus, Ice)",
        category: ShishaCategory.MODERN,
        icons: [
            {
                name: "mdi-fruit-pineapple",
                color: "#563c0d"
            } as Icon
        ],
    },
    {
        name: "True Passion Pista Wave",
        description: "(Pistazie)",
        category: ShishaCategory.MODERN,
        icons: [
            {
                name: "mdi-fruit-pineapple",
                color: "#563c0d"
            } as Icon
        ],
    },
    {
        name: "Love 66",
        description: "(Maracuja, Melone)",
        category: ShishaCategory.MODERN,
        icons: [
            {
                name: "mdi-fruit-pineapple",
                color: "#563c0d"
            } as Icon
        ],
    },
    {
        name: "Maridan Tingle Tangle",
        description: "(Grapefruit, Limette, Maracuja)",
        category: ShishaCategory.MODERN,
        icons: [
            {
                name: "mdi-fruit-pineapple",
                color: "#563c0d"
            } as Icon
        ],
    },
    {
        name: "Sommer in Beirut",
        description: "(Himbeere, Wassermelone, Zitrone)",
        category: ShishaCategory.MODERN,
        icons: [
            {
                name: "mdi-fruit-pineapple",
                color: "#563c0d"
            } as Icon
        ],
    },
    {
        name: "Blaulicht",
        description: "(Blaubeere)",
        category: ShishaCategory.MODERN,
        icons: [
            {
                name: "mdi-fruit-pineapple",
                color: "#563c0d"
            } as Icon
        ],
    },
    {
        name: "Bruderherz",
        description: "(Erdbeere, Mango, Ananas)",
        category: ShishaCategory.MODERN,
        icons: [
            {
                name: "mdi-fruit-pineapple",
                color: "#563c0d"
            } as Icon
        ],
    },
    {
        name: "Blut gegen Blut",
        description: "(Zitrone und Drachenfrucht)",
        category: ShishaCategory.MODERN,
        icons: [
            {
                name: "mdi-fruit-pineapple",
                color: "#563c0d"
            } as Icon
        ],
    },
    {
        name: "Weitere Sorten...",
        description: "(Al Massiva Tobacco)",
        category: ShishaCategory.MODERN,
        icons: [
            {
                name: "mdi-fruit-pineapple",
                color: "#563c0d"
            } as Icon
        ],
    },
    {
        name: "Aqua Blueberry",
        description: "(Blaubeere)",
        category: ShishaCategory.MODERN,
        icons: [
            {
                name: "mdi-fruit-pineapple",
                color: "#563c0d"
            } as Icon
        ],
    },
    {
        name: "Aqua Wild Mango",
        description: "(Mango, Menthol)",
        category: ShishaCategory.MODERN,
        icons: [
            {
                name: "mdi-fruit-pineapple",
                color: "#563c0d"
            } as Icon
        ],
    },
    {
        name: "Aqua Fanex",
        description: "(Maracuja)",
        category: ShishaCategory.MODERN,
        icons: [
            {
                name: "mdi-fruit-pineapple",
                color: "#563c0d"
            } as Icon
        ],
    },
    {
        name: "Schwarze Dose",
        description: "(Acai, Guarana, Zitrone)",
        category: ShishaCategory.MODERN,
        icons: [
            {
                name: "mdi-fruit-pineapple",
                color: "#563c0d"
            } as Icon
        ],
    },


];