import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_BodyComponent = _resolveComponent("BodyComponent")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_HeaderComponent, { "onItem:clicked": _ctx.scroll }, null, 8, ["onItem:clicked"]),
      _createVNode(_component_BodyComponent, { "scroll-element": _ctx.scrollElement }, null, 8, ["scroll-element"]),
      _createVNode(_component_FooterComponent)
    ]),
    _: 1
  }))
}