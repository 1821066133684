<template>
    <div class="map">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2623.2606277501854!2d9.190645076969163!3d48.891369971336545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4799d1b8bb06ad15%3A0x5ec5b253f9b7de0f!2sHangover%20Shisha%20Bar%20Lounge!5e0!3m2!1sde!2sde!4v1686943881667!5m2!1sde!2sde"
            width="100%" height="550" allowfullscreen loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        <v-card-actions>
            <v-btn variant="outlined" prepend-icon="mdi-map" :href="openNativeMaps()">In Maps öffnen</v-btn>
        </v-card-actions>
    </div>
</template>

<script lang="ts">
import { deviceOs } from '@/utils/device';
import { defineComponent } from 'vue';

export default defineComponent({
    data: () => {
        return {
            webAddress: 'https://www.google.com/maps/place/Hangover+Shisha+Bar+Lounge/@48.89137,9.1906451,17z/data=!3m1!4b1!4m6!3m5!1s0x4799d1b8bb06ad15:0x5ec5b253f9b7de0f!8m2!3d48.89137!4d9.19322!16s%2Fg%2F11b6bt9jx8?authuser=0&entry=ttu',
            iosAddress: 'http://maps.apple.com/?q=Hangover+Shisha+Bar+Lounge/@48.89137,9.1906451,17z/data=!3m1!4b1!4m6!3m5!1s0x4799d1b8bb06ad15:0x5ec5b253f9b7de0f!8m2!3d48.89137!4d9.19322!16s%2Fg%2F11b6bt9jx8?authuser=0&entry=ttu',
            androidAddress: 'https://maps.google.com/?q=Hangover+Shisha+Bar+Lounge/@48.89137,9.1906451,17z/data=!3m1!4b1!4m6!3m5!1s0x4799d1b8bb06ad15:0x5ec5b253f9b7de0f!8m2!3d48.89137!4d9.19322!16s%2Fg%2F11b6bt9jx8?authuser=0&entry=ttu',
        }
    },
    methods: {
        openNativeMaps(): string {
            const os = deviceOs();
            if (os === 'android') {
                return this.androidAddress;
            }
            if (os === 'ios') {
                return this.iosAddress;
            }
            return this.webAddress;
        },
        deviceOs
    }
})
</script>

<style scoped>
.map {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 20px 20px 3px;
    background-color: #3c3c3c;
}
</style>