<template>
    <v-expansion-panel :title="title">
        <template #text>
            <v-list lines="two" density="compact">
                <v-list-item v-for="drink in products" :key="drink.name">
                    <template #title>
                        <div class="icon-text">
                            <p>{{ drink.name }}</p>
                            <p id="price">{{ drink.price.toFixed(2) }}</p>
                        </div>
                    </template>
                    <template #subtitle>
                        <p>{{ drink.description }}</p>
                    </template>
                    <v-divider thickness="2" inset></v-divider>
                </v-list-item>
            </v-list>
        </template>
    </v-expansion-panel>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { Drink } from '@/collections/drinks';


export default defineComponent({

    props: {
        title: {
            type: String,
            required: true,
        },
        products: {
            type: Array as PropType<Drink[]>,
            required: false,
            default: () => []
        }
    }
})

</script>

<style scoped>
.icon-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon-text>p {
    padding-right: 10px;
}

.price {
    color: orange;
    margin-left: 10px;
    font-style: italic;
}

.title {
    display: inline-flex;
}

#price {
    font-style: italic;
    color: orange;
}

#price::after {
    content: ' €';
}
</style>
