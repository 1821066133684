import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconText = _resolveComponent("IconText")!
  const _component_v_app_bar_title = _resolveComponent("v-app-bar-title")!
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_app_bar, {
        class: "app-bar",
        elevation: "2",
        color: "purple",
        height: "80",
        floating: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_app_bar_title, {
            onClick: _ctx.refresh,
            class: "hover"
          }, {
            text: _withCtx(() => [
              _createVNode(_component_IconText)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_v_app_bar_nav_icon, {
            variant: "text",
            onClick: _withModifiers(_ctx.onDrawerClick, ["stop"])
          }, null, 8, ["onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_navigation_drawer, {
        modelValue: _ctx.drawer,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.drawer) = $event)),
        location: "right",
        temporary: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_list, {
            items: _ctx.items,
            "item-value": "value",
            "onClick:select": _ctx.onDrawerItemClick
          }, null, 8, ["items", "onClick:select"])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}