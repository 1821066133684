import { useDisplay } from 'vuetify'

export function isDesktop(): boolean {
    const { width } = useDisplay()
    return width.value > 900;
}

export function deviceHeight(): number {
    const { height } = useDisplay()
    return height.value;
}

export function deviceOs(): any {
    const { platform } = useDisplay()
    if(platform.value.android) {
        return 'android';
    }
    if(platform.value.ios) {
        return 'ios';
    }
    return 'desktop';
}