<template>
  <v-parallax v-if="!isDesktop()" :height="(deviceHeight() - 50)" src="@/assets/images/hangover-3-mobile.png">
    <div class="d-flex flex-column fill-height justify-center align-center text-white">
      <h1 class="text-h4 font-weight-thin mb-4 mainheader">
        Hangover-Lounge
      </h1>
      <h3 class="text-h4 font-weight-thin mb-4 subheading">
        "Willkommen in einer der ersten Shisha-Bars in Ludwigsburg!"
      </h3>
    </div>
  </v-parallax>
  <v-parallax v-else :height="(deviceHeight() - 50)" src="@/assets/images/hangover-4-pc.png">
    <div class="d-flex flex-column fill-height justify-center align-center text-white">
      <h1 class="text-h4 font-weight-thin mb-4 mainheader">
        Hangover-Lounge
      </h1>
      <h3 class="text-h4 font-weight-thin mb-4 subheading">
        "Willkommen in einer der ersten Shisha-Bars in Ludwigsburg!"
      </h3>
    </div>
  </v-parallax>
  <div>
    <v-divider thickness="1" color="#660066" />
  </div>
  <div :class="{ carousel: isDesktop() }">
    <v-carousel ref="gallery" :height="(deviceHeight() - 180)" hide-delimiters
      :style="isDesktop() ? 'border-radius: 20px;' : ''" :cycle="isDesktop()">
      <v-carousel-item v-for="image in images" :key=image :src=image cover></v-carousel-item>
    </v-carousel>
  </div>
  <div class="mt-10 mb-3">
    <v-divider thickness="6" inset color="black" />
  </div>
  <div :class="{ productColumns: isDesktop(), mobile: !isDesktop() }">
    <ShishaProducts ref="shishas"></ShishaProducts>
    <DrinkProducts ref="drinks"></DrinkProducts>
  </div>
  <div class="mt-10 mb-3">
    <v-divider thickness="6" inset color="black" />
  </div>
  <div class="footer">
    <OpeningHoursCard ref="contactNlocation"></OpeningHoursCard>
    <MapsComponent v-if="isDesktop()"></MapsComponent>
  </div>
</template>
  
<script lang="ts">
import { defineComponent } from 'vue';
import ShishaProducts from './ShishaProducts.vue';
import DrinkProducts from './DrinkProducts.vue';
import OpeningHoursCard from './common/OpeningHoursCard.vue';
import { isDesktop, deviceHeight } from '../utils/device';
import MapsComponent from './common/MapsComponent.vue';

export default defineComponent({
  name: "BodyComponent",
  components: {
    ShishaProducts,
    DrinkProducts,
    OpeningHoursCard,
    MapsComponent
  },
  props: {
    scrollElement: {
      type: String,
      required: false,
    }
  },
  watch: {
    scrollElement(): void {
      if (this.scrollElement && this.scrollElement !== '') {
        (this.$refs[this.scrollElement] as any)?.$el.scrollIntoView({
          behavior: 'smooth', block: 'center',
          inline: 'center'
        });
      }
    }
  },
  async beforeMount() {
    this.images = isDesktop() ? require.context('@/assets/images', true, /^.*-pc\.png$/, "lazy").keys().map((image) => require('@/assets/images' + image.slice(1))) : require.context('@/assets/images', true, /^.*-mobile\.png$/, "lazy").keys().map((image) => require('@/assets/images' + image.slice(1)));
  },
  data() {
    return {
      images: [] as string[],
    }
  },
  methods: {
    pickParallaxImage(): string {
      if (isDesktop()) {
        return '@/assets/images/hangover-1-main.png';
      }
      return '@/assets/images/hangover-1-pc.png';
    },
    isDesktop,
    deviceHeight
  }
})
</script>

<style lang="scss" scoped>
h2 {
  padding-right: 15px;
}

.append-icon-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.subheading {
  text-align: center;
}

.productColumns {
  display: flex;
  align-items: flex-start;
  flex-direction: row-reverse;

  >* {
    flex: 1;
  }
}

.footer {
  background-color: black;
  display: flex;
}

.footer>* {
  flex: 1;
}

.carousel {
  padding: 20px 40px 20px 40px;
  background: rgb(55, 2, 133);
  background: linear-gradient(180deg, rgba(55, 2, 133, 1) 18%, rgba(14, 26, 115, 1) 44%, rgba(18, 24, 64, 1) 77%, rgb(218, 135, 10) 100%);
}
</style>
  
  
  