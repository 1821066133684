<template>
    <div class="prepend-icon-text">
        <v-img src="../../assets/logo-cropped.png" :max-width="68" />
        <h2>HANGOVER-LOUNGE</h2>
    </div>
</template>

<style scoped>
.prepend-icon-text {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

h2 {
    font-size: x-large;
    font-family: pixie;
}

@media only screen and (min-width: 768px) {
    h2 {
        padding-left: 20px;
        font-size: xxx-large;
        font-family: pixie;
    }

    .prepend-icon-text {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>