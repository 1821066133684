<template>
    <div class="ml-5 mr-5">
        <h1> GETRaNKE </h1>
        <v-expansion-panels>
            <ExpansionPanelDrinks title="Heißgetränke" :products="hotDrinks"></ExpansionPanelDrinks>
            <ExpansionPanelDrinks title="Alkoholfreie Getränke" :products="noAlcDrinks"></ExpansionPanelDrinks>
            <ExpansionPanelDrinks title="Bier" :products="beer"></ExpansionPanelDrinks>
            <ExpansionPanelDrinks title="Longdrinks" :products="longDrinks"></ExpansionPanelDrinks>
            <ExpansionPanelDrinks title="Cocktails" :products="cocktails"></ExpansionPanelDrinks>
            <ExpansionPanelDrinks title="Alkoholfreie Cocktails" :products="noAlcCocktails"></ExpansionPanelDrinks>
            <ExpansionPanelDrinks title="Aperitifs" :products="aperitifs"></ExpansionPanelDrinks>
            <ExpansionPanelDrinks title="Wein" :products="vine"></ExpansionPanelDrinks>
            <ExpansionPanelDrinks title="Shots" :products="shots"></ExpansionPanelDrinks>
            <ExpansionPanelDrinks title="Flaschen" :products="bottles"></ExpansionPanelDrinks>
        </v-expansion-panels>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { DrinksCategory, drinks } from '@/collections/drinks';
import ExpansionPanelDrinks from './common/ExpansionPanelDrinks.vue';


export default defineComponent({
    components: { ExpansionPanelDrinks },
    data: () => {
        return {
            hotDrinks: drinks.filter((drink) => drink.category === DrinksCategory.HOTDRINKS),
            noAlcDrinks: drinks.filter((drink) => drink.category === DrinksCategory.NOALCDRINKS),
            beer: drinks.filter((drink) => drink.category === DrinksCategory.BEER),
            longDrinks: drinks.filter((drink) => drink.category === DrinksCategory.LONGDRINKS),
            cocktails: drinks.filter((drink) => drink.category === DrinksCategory.COCKTAILS),
            noAlcCocktails: drinks.filter((drink) => drink.category === DrinksCategory.NOALCCOCKTAILS),
            aperitifs: drinks.filter((drink) => drink.category === DrinksCategory.APERITIFS),
            vine: drinks.filter((drink) => drink.category === DrinksCategory.VINE),
            shots: drinks.filter((drink) => drink.category === DrinksCategory.SHOTS),
            bottles: drinks.filter((drink) => drink.category === DrinksCategory.BOTTLES)
        }
    }
})
</script>

<style scoped>
h1 {
    text-align: center;
    font-family: pixie;
}
</style>