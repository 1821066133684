// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { mdi } from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css'



export default createVuetify(
  {
    components, directives, icons:{ defaultSet: 'mdi', sets: { mdi}}
  }
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
)
